<template>
  <div
    :class="['innerbanner', isLanding ? 'mt-0 d-block': '', hideCondition ? 'breadcrumb-hide': '']"
    :style="backgroundImageStyle" :aria-label="imageCaption"
    v-if="isEINAVBUAgency"
  ></div>
  <div
    :class="['innerbanner', isLanding ? 'mt-0 d-block': '', hideCondition ? 'breadcrumb-hide': '']"
    :style="backgroundImageStyle" :aria-label="imageCaption"
    v-else-if="!hideDealCondition && imageSrc"
  ></div>
  <!-- <div
    class="innerbanner"
    :style="'background-image: url(${imageDomain}/assets/img/search-result-banner.png); background-size: 100% 100%;z-index: inherit;'"
    v-if="!isAgencyLogin"
  ></div> -->
    <div style="height: 50px;" v-else></div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [imageUrlMixin],
  props: {
    banner: {
      type: String,
      default: '',
    },
    imageCaption: {
      type: String,
      default: 'Description of the background image',
    },
    hotelCityCode: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      isEINAVBUAgency: (state) => state.agencyUsers.isEINAVBUAgency,
    }),
    ...mapGetters({
      device: 'GET_DEVICE',
      isAgencyLogin: 'IS_AGENCY_AUTHORIZED',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isLanding: 'GET_IS_LANDING_PAGE',
      isMaccabiAgency: 'GET_IS_MACCABI_AGENCY',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
      limitDealsState: 'GET_LIMIT_DEALS_STATE',
      is1MaccabiFCLSHAVAgency: 'GET_IS_1_MACCABI_AGENCY',
      is1MAMISHAgency: 'GET_IS_1MAMISH_AGENCY',
      isRIBBONAgency: 'GET_IS_RIBBON_AGENCY',
    }),
    hideCondition() {
      const { isAgencyLogin, bypassPaymentState, limitDealsState, isMaccabiAgency } = this;
      return !isMaccabiAgency && ((!isAgencyLogin && bypassPaymentState) || limitDealsState);
    },
    imageSrc() {
      if (this.banner && (typeof this.banner === 'string' || this.banner instanceof String)) {
        return this.banner?.replaceAll(' ', '%20');
      }
      return '';
    },
    hideDealCondition() {
      const { isAgencyLogin, isOdysseySite, isMaccabiAgency } = this;
      return !isMaccabiAgency && isAgencyLogin && !isOdysseySite;
    },
    backgroundImageStyle() {
      const isDesktop = (this.device === 'desktop');
      if (this.isEINAVBUAgency) {
        let str = '';
        if (this.hotelCityCode === 'GRR' && isDesktop) {
          str = `background-image: url(${this.imageDomain}/assets/img/EINAVBU-banner/EINAVBU-GRR-desktop.png)`;
        } else if (this.hotelCityCode === 'GRR' && !isDesktop) {
          str = `background-image: url(${this.imageDomain}/assets/img/EINAVBU-banner/EINAVBU-GRR-mobile.png); height: 72px;background-size: contain;display: block;margin-top: 0;`;
        } else if (isDesktop) {
          str = `background-image: url(${this.imageDomain}/assets/img/EINAVBU-banner/EINAVBU-product-desktop.png)`;
        } else {
          str = `background-image: url(${this.imageDomain}/assets/img/EINAVBU-banner/EINAVBU-product-mobile.png); height: 72px;background-size: contain;display: block;margin-top: 0;`;
        }
        return str;
      } else if ((this.is1MaccabiFCLSHAVAgency || this.isRIBBONAgency) && !this.is1MAMISHAgency) {
        return `background-image: url(${this.imageDomain}/assets/img/1maccabi-fclshav-product-banner.png)`;
      } else if (this.imageSrc) {
        return `background-image: url("${this.imageSrc}")`;
      } else {
        return `background-image: url(${this.imageDomain}/assets/img/terminal02.jpg)`;
      }
    },
  },
  mounted() {
  },
};
</script>
<style scoped>
  .innerbanner{
    margin-top: 3.5rem;
  }
  .innerbanner.breadcrumb-hide{
    margin-top: 6.3rem;
  }

  .desktop-app .innerbanner {
    display: none;
  }

  @media (max-width: 768px) {
  .innerbanner {
      min-height: 72px !important;
      background-size: contain;
      background-position: center center;
  }
  }
</style>
